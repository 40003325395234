import { ForwardedRef, forwardRef } from "react";
import { ButtonProps, Button } from "antd";

const CustomizeButton = (
  { children, ...props }: ButtonProps,
  ref: ForwardedRef<HTMLButtonElement>
): JSX.Element => (
  <Button ref={ref} {...props}  >
    {children}
  </Button>
);

export default forwardRef<HTMLButtonElement, ButtonProps>(CustomizeButton);
